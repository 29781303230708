.DateInput_input {
   height: auto;
   display: block;
   width: 100%;
   padding: 0.375rem 1rem;
   font-size: 1rem;
   font-weight: 300;
   line-height: 1.35;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-radius: 0.2rem;
}

.CalendarDay__selected,
.CalendarDay__selected:hover {
   background: #0276d4;
   border-color: #33a1fd;
}

.CalendarDay__today {
   box-shadow: 0 0 0 2px inset #e4e7e7;
   box-shadow: 0 0 0 2px inset #d1d6d6;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before,
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
   border-right-color: #0276d4;
}
